<template>
    <div class="seting-box">
      <s-header :name="'ADDRESS'"></s-header>
      <div class="input-item">
        <van-field v-model="userName" label="NAME" />
        <van-field v-model="userEmail" type='EMAIL' label="EMAIL" />
      </div>
      <van-button round class="save-btn" color="#1baeae" type="primary" @click="save" block>SAVE</van-button>
    </div>
  </template>
  
  <script>
  import sHeader from '@/components/SimpleHeader'
  import { addAddress ,getDefaultAddress} from '../service/address'
  import { Toast } from 'vant'
  export default {
    components: {
      sHeader
    },
    data() {
      return {
        userName: '',
        //userPhone: '',
        userEmail: ''
      }
    },
    async mounted() {
      const { data } = await getDefaultAddress()
      this.userName = data.userName
      //this.userPhone = data.userPhone
      this.userEmail = data.userEmail
    },
    methods: {
        async onSave(content) {
            const params = {
            userName: content.name,
            userPhone: content.tel
        }
        const { message } =  addAddress(params)
        Toast.success('save success')
    },
        
    }
  }
  </script>
  
  <style lang="less" scoped>
    .seting-box {
      .input-item {
        margin-top: 44px;
      }
      .save-btn {
        width: 80%;
        margin: 20px auto ;
      }
    }
  </style>
  